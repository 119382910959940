import React,{useState} from 'react';
import {Accordion,AccordionSummary,AccordionDetails,Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Producto from "./Producto";
import Loader from './Loader';

const useStyles = makeStyles(() => ({
    expanded: 
    {
        margin:0
    },
    content: 
    {
        margin:0,
      '&$expanded': {
        margin: 0,
      },
    },
  }));

const Categoria = ({categoria}) =>
{
  const {id,data}=categoria;
  const classes=useStyles();
  const {Nombre,APIPath,Imagen,Cartel,Items}=data;
  const [openCategoria, setopenCategoria] = useState(false);
  
  const ProductosCategoria=()=>
  {
    if(Items.length>0)
    {
      return Items.map(producto => <Producto producto={producto}/>)
    }
    else
    {
      return <Loader/>     
    }
  }

  
  const Title=<p className="categoria-titulo">{String(Nombre).toUpperCase()}</p>

  return <div style={{borderBottom:"1px solid #444444"}} >
          <Accordion  expanded={openCategoria} onChange={() => setopenCategoria(!openCategoria)}>
          <AccordionSummary style={{backgroundColor:"#D0D0D0"}} classes={{ content: classes.content, expanded: classes.expanded }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
            <Typography  className={classes.root}>{Title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{display:"block",padding:0,margin:0}}>
            <ProductosCategoria/>
          </AccordionDetails>
          </Accordion>
          </div>
}

export default Categoria
