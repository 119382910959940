import styled from "styled-components";

const LoaderUI=styled.div`
margin:20px auto;
border: 16px solid #f3f3f3;
border-radius: 50%;
border-top: 16px solid #3498db;
width: 120px;
height: 120px;
-webkit-animation: spin 2s linear infinite;
animation: spin 2s linear infinite;
  
@keyframes spin 
{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width:768px)
{
    .Foodlist{grid-template-columns: 1fr;}
}`;

const Loader=()=> <LoaderUI/>

export default Loader;