import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/database";


if (!firebase.apps.length)
 {
    firebase.initializeApp(
      {
         apiKey: "AIzaSyDVTOQFoT-yuGiOJE964wrMxsccDKlXhxs",
         authDomain: "cartabaum.firebaseapp.com",
         databaseURL: "https://cartabaum.firebaseio.com",
         projectId: "cartabaum",
         storageBucket: "cartabaum.appspot.com",
         messagingSenderId: "382167524148",
         appId: "1:382167524148:web:63b2ad5de6e7e732327b61",
         measurementId: "G-PZS3WQJXSB"
      });
 }else {
    firebase.app(); // if already initialized, use that one
 }

 
const DB=firebase.database();

export default DB;