import React,{useState,useContext,useEffect} from 'react';
import CarritoContext from "../context/CarritoContext";
import {Dialog,DialogContent, DialogTitle, DialogActions, Button,IconButton} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Variable from "./Variable";
import AppContext from '../context/AppContext';
import styled from "styled-components";
import Alert from './Alert';

const StyledIMG=styled.img`
width:100%;
margin:20px 0;`;

const ContainerVariables=styled.div`
margin:10px;`;

const ProductoModal = ({open,onCloseModal,Producto}) => 
{
    const {ProductosCarrito, setProductosCarrito}=useContext(AppContext)
    const {Nombre,Imagen,Descripcion,Precio,Variables,VariablesSimultaneas}=Producto;
    const contieneVariables=Variables!==undefined;
    
    const [Cantidad, setCantidad] = useState(1);
    const [Error,setError]=useState(undefined);

    const closeModal=()=>
    {
        onCloseModal();
    }

    const handleAddToCart=()=>
    {
        if(contieneVariables)
        {
            if(VariablesSimultaneas >0)
            {
                const VariablesArray=Object.entries(Variables).map(variable => 
                {
                    const Cantidad= Number(document.getElementById(variable[0]).innerText);
                    return {id:variable[0],data:variable[1],Cantidad}
                }).filter(variable => variable.Cantidad >0);

                var CantidadTotal=0;

                VariablesArray.map(variable => CantidadTotal=CantidadTotal+variable.Cantidad);
                
                if(CantidadTotal===VariablesSimultaneas)
                {
                    var Detalles="";
                    
                    VariablesArray.map(variable =>
                    {   
                        Detalles=Detalles+ `\n${variable.Cantidad} x ${variable.data.Nombre}`;  
                    });
                    
                    const producto=
                    {
                        id:Date.now(),
                        data:
                        {
                            Nombre,
                            Detalles,
                            Cantidad,
                            Precio,
                            Imagen,
                        }
                    }
                    
                    setProductosCarrito([...ProductosCarrito,producto])
                    onCloseModal();
                }
                else
                {
                   setError(`La cantidad total debe ser igual a ${VariablesSimultaneas}`)
                }
            }
            else
            {
                const VariablesArray=Object.entries(Variables).map(variable => 
                {
                    const Cantidad= Number(document.getElementById(variable[0]).innerText);
                    return {id:variable[0],data:variable[1],Cantidad}
                }).filter(variable => variable.Cantidad >0);
                  
                if(VariablesArray.length>0)
                {
                    var Detalles="";
                    var PrecioFinal=0;
    
                    VariablesArray.map(variable =>
                    {   
                        Detalles=Detalles+ `\n${variable.Cantidad} x ${variable.data.Nombre}`;  
                        const PrecioVariable=variable.data.Precio>0 ? variable.data.Precio : Precio
                        PrecioFinal=PrecioFinal+(PrecioVariable * variable.Cantidad);      
                    });

        
                    const producto=
                    {
                        id:Date.now(),
                        data:
                        {
                            Nombre,
                            Detalles,
                            Cantidad,
                            Precio:PrecioFinal,
                            Imagen,
                        }
                    }
                    
                    setProductosCarrito([...ProductosCarrito,producto])
                    onCloseModal();
                }
                else
                {
                   setError("Debes seleccionar  al menos una opción!");
                }
            }
        }
        else
        {
            const producto={
                id:Date.now(),
                data:
                {
                    Nombre,
                    Cantidad,
                    Precio,
                    Imagen,
                }
            }
            setProductosCarrito([...ProductosCarrito,producto])
            onCloseModal();
        }    
    }   

    const VariablesManager=()=>
    {       
        if(contieneVariables)
        {
            const VariablesArray=Object.entries(Variables).map(variable =>
            {
                const id=variable[0];
                const data=variable[1];

                return {id,data}; 
            });
     
            return <ContainerVariables>
                { VariablesArray.map(variable =>
                {
                    return <Variable variable={variable} VariablesSimultaneas={VariablesSimultaneas} Variables={Variables}/>
                })}
            </ContainerVariables>;
        }

        const Remove=()=>
        {
            const handleClick=()=>
            {
                setCantidad(Cantidad-1);
            }
            
            const Activado=Cantidad>1;
            const color=Activado ? "#3f51b5" : "#9a9a9a";
            
            return <IconButton disabled={!Activado} style={{color}} onClick={handleClick} > 
                <RemoveCircleOutlineIcon style={{fontSize:"25px"}}/>
            </IconButton>    
        }

        const Increment=()=>
        {
            const handleClick=()=>
            {
                setCantidad(Cantidad+1)    
            }
    
            const Activado=Cantidad<10
            const color=Activado ? "#3f51b5" : "#9a9a9a";
            
            return <IconButton disabled={!Activado} style={{color}} onClick={handleClick} > 
                <AddCircleOutlineIcon style={{fontSize:"25px"}}/>
            </IconButton> 
        }

        return ( 
        <div className="Cantidad-productoModal">
            <Remove/>
            <p style={{fontSize:"20px"}}>{Cantidad}</p>
            <Increment/>
        </div>)
    }

    return <Dialog open={open} onClose={closeModal}>
        <DialogTitle>{Nombre}</DialogTitle>
        <DialogContent>
            <StyledIMG src={Imagen}  alt={`imagen de ${Nombre}`}/>
            <p style={{marginBottom:"10px"}}>{Descripcion}</p>
           <VariablesManager/> 
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={closeModal}>Cerrar</Button>
                <Button variant="contained" color="primary" onClick={handleAddToCart}>Agregar</Button>
            </DialogActions>
        </DialogContent>
        <Alert mensaje={Error} close={() => setError(undefined)}/>
    </Dialog>
}

export default ProductoModal
