import React from 'react';
import Appbar from '../components/appbar';
import Categoria from "../components/categoria";
import WhatsappButton from '../components/WhatsappButton';
import CarritoDesktop from '../components/CarritoDesktop';

const HomeDesktop = ({categorias}) => 
{
    const PrimeraFila=categorias.map((categoria,index) =>
    {
        const esPar= (index % 2) == 0;
        if(esPar)
        {
            return categoria
        }
    })

    const SegundaFila=categorias.map((categoria,index) =>
    {
        const esPar= (index % 2) == 0;
        if(!esPar)
        {
            return categoria
        }
    })

    return <div className="home-container">
        <div>
            <Appbar/>
            <div className="container-categoriasDesktop">
            <div>{PrimeraFila.map(categoria => categoria!==undefined ? <Categoria categoria={categoria}/> : "")}</div>
            <div>{SegundaFila.map(categoria => categoria!==undefined ? <Categoria categoria={categoria}/> : "")}</div>
            <WhatsappButton show={true} bottom={10} number="5491138141940"/>
        </div>
        </div>
        <CarritoDesktop/>
        </div>
}

export default HomeDesktop
