import React from 'react';
import styled from "styled-components";

const Container=styled.div`
position:absolute;
top:50%;
left:50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);`;


const Centralizador = ({children}) => 
{
    return (
        <Container>
           {children}
        </Container>
    )
}

export default Centralizador
