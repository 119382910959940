import React,{useState,useContext,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import AppContext from '../../context/AppContext';
import Appbar from '../../components/appbar'
import {TextField,Button} from "@material-ui/core";
import Centralizador from '../../components/Centralizador';
import axios from 'axios';
import useScript from '../../hooks/useScript';


const Pago = () => 
{
    const {Envio,ProductosCarrito,Cupon} = useContext(AppContext);
    const [PrecioFinal, setPrecioFinal] = useState(undefined);
    const [Opcion, setOpcion] = useState("Efectivo");
    const [AbonaCon, setAbonaCon] = useState("0");
    const [ErrorVuelto, setErrorVuelto] = useState(false);
    let history = useHistory();

    useEffect(() => 
    {
        var total=0;
        if(Envio.Tipo==="Delivery")
        {
            total+=90;
        }
        ProductosCarrito.map(producto =>
        {
            const {data}=producto;
            const {Precio,Cantidad}=data;
            let precioProducto=Precio*Cantidad;
            total+=precioProducto;
        })

        if(Cupon)
        {
            total = total - (total *10 / 100);
        }

        setPrecioFinal(total);
        setAbonaCon(total);

        if(Envio.Direccion===undefined && Envio.Tipo!=="Delivery")
        {
            history.push("/pedido/pago")
        }

        if(Envio.Tipo!=="Delivery")
        {
            history.push("/pedido/pago")
        }
        
    }, [])

    const Opciones=["Efectivo","Tarjeta","Transferencia Bancaria"];

    const Continuar=()=>
    {
        Envio.TipoPago=Opcion;
        Envio.AbonaCon=AbonaCon;     

        history.push("/pedido/resumen")    
    }


    const ButtonContinuar=()=>
    {
        const habilitado=()=>
        {
            if(ErrorVuelto)
            {
                return false;
            }
            if(Opcion==="Efectivo")
            {
                if(Number(AbonaCon)>=0)
                {
                    return true;
                }
                return false;
            }
            else
            {
                return true;
            }
        }

        return <Button  color="primary" style={{margin:"10px"}} variant="contained" disabled={!habilitado()} onClick={Continuar}>Continuar</Button>
    }

    const Error=()=>
    {
        if(ErrorVuelto)
        {
            return <p style={{color:"red",fontWeight:"bold",fontSize:"15px"}}>El monto debe ser igual o mayor</p>
        }
        else
        {
            return "";
        }
    }

    return (
        <div>
            <script src="https://sdk.mercadopago.com/js/v2"></script>
            <Appbar/>
            <Centralizador>
                <h2>{`Total $${Number(PrecioFinal)}`}</h2>
                <div>
            {Opciones.map(opcion =>
                {
                    if(opcion==="Efectivo")
                    {
                        return <>
                        <div style={{margin:"10px"}} ><label> <input type="radio" checked={Opcion === opcion} value={opcion} onChange={e => setOpcion(e.target.value)} name="tipo"/>{opcion}</label></div>
                        {Opcion==="Efectivo" ?  <div><TextField variant="outlined" type="number" label="Abonas con" size="small" value={AbonaCon} onChange={e => 
                        {
                            if(e.target.value<PrecioFinal)
                            {
                                setAbonaCon(e.target.value)
                                setErrorVuelto(true);
                            }
                            else
                            {
                                setAbonaCon(e.target.value);
                                setErrorVuelto(false);
                            }
                        }}/></div> : ""}
                        <Error/>
                        </>
                    }
                    
                    return <div style={{margin:"10px"}} ><label> <input type="radio" checked={Opcion === opcion} value={opcion} onChange={e => 
                        {
                            setOpcion(e.target.value);
                            setErrorVuelto(false);
                        }} name="tipo"/>{opcion}</label></div>
                  
                })}
        </div>
               
            <ButtonContinuar/>
            </Centralizador>
        <div id="mercadopagoUI"></div>
        </div>
    )
}

export default Pago
