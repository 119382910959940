import React,{useContext} from 'react';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AppContext from '../context/AppContext';
import { makeStyles } from '@material-ui/core';

const useStyles=makeStyles(
    {

        Title:
        {
            alignSelf:"center",
            margin:"10px"
        },
    })


const AppbarMobile = ({openCarrito}) =>
{
    const {ProductosCarrito}=useContext(AppContext)
    
    const classes=useStyles();
    return <div className="appbar-container">
        <img alt="Imagen de Baum Lanús" src="https://i.imgur.com/O0JFzFr.jpeg" className={"image-appbar"} />
    <h2 className="appbar-title">Menú Digital</h2>
   <Badge badgeContent={ProductosCarrito.length} color="primary" className="appbar-mobile-carrito" onClick={openCarrito}>
       <ShoppingCartIcon  />
    </Badge>
    </div>
}

export default AppbarMobile
