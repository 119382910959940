import React,{useState,useEffect} from "react";
import Appbar from '../components/appbar';
import Loader from '../components/Loader';
import useCategorias from '../hooks/useCategorias';
import HomeDesktop from "../contents/HomeDesktop";
import HomeMobile from "../contents/HomeMobile";
import Popups from "../components/Popups";
import '../styles/appbar.css'
import '../styles/carrito.css'
import '../styles/categoria.css'
import '../styles/HomeDesktop.css'
import '../styles/Homepage.css'
import '../styles/index.css'
import '../styles/producto.css'
import '../styles/productoCarrito.css'
import '../styles/WhatsappButton.css'


const Index=()=>
{
    const [DesktopPage, setDesktopPage] = useState(true);
    const Categorias=useCategorias();
    
    useEffect(() => 
    {
        if (typeof window !== "undefined") 
        {
            setDesktopPage(window.matchMedia("(max-width: 678px)").matches);
        }
    }, [])

    if(Categorias.length>0)
    {
        return <>
        {DesktopPage ? <HomeMobile categorias={Categorias}/> : <HomeDesktop categorias={Categorias}/>}
        <Popups/>
    </>
    }
    else
    {
        return <>
            <Appbar/>
            <Loader/>
        </>
    }
}

export default Index;