import React,{useContext,useState} from 'react';
import { useHistory } from "react-router-dom";
import ProductoCarrito from "../components/ProductoCarrito";
import CuponComponent from "./Cupon";
import {Button, TextField } from '@material-ui/core';
import AppContext from '../context/AppContext';

const CarritoDesktop = () => 
{
    const {Cupon,ProductosCarrito}=useContext(AppContext);

    let history = useHistory();

    const Total=()=>
    {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        });

        let total =ProductosCarrito.reduce((total,producto)=>
        {
            const {data}=producto;
            const {Precio,Cantidad}=data;
            let precioProducto=Precio*Cantidad;
            return total+precioProducto;
        },0);

       
        if(Cupon)
        {
            total = total - (total * 10 / 100);
        }   

        return <div>
            <p className="Total-carritoDesktop">Subtotal <b>{formatterPeso.format(total)}</b></p>
        </div>
    }

    const ProductosList=()=>
    {
        if(ProductosCarrito.length>0)
        {
           
           return <div>
               {ProductosCarrito.map(producto => <ProductoCarrito  producto={producto}/>)}
           </div>
        }
        return "";
    }

    const enviarPedido=()=>
    {
        history.push("/pedido/horario");
    }

 

    let FinalizarActivado=ProductosCarrito.length===0;

    return <div className="Container-carritoDesktop">
        <p className="Nuevo-pedido-label">Nuevo Pedido</p>
        <ProductosList/>   
        <CuponComponent/>
        <Total/>
        <Button disabled={FinalizarActivado} style={{display:"flex",marginLeft:"auto",marginTop:"10px",marginBottom:"100px"}} variant="contained" color="primary" onClick={enviarPedido}>Finalizar Pedido</Button>
       
    </div>
}

export default CarritoDesktop
