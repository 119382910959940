import React,{useState} from 'react';
import AppbarMobile from '../components/AppbarMobile';
import Categoria from "../components/categoria";
import WhatsappButton from '../components/WhatsappButton';
import CarritoMobile from "../components/CarritoMobile"


const HomeMobile = ({categorias}) => 
{
  const [openCarrito, setopenCarrito] = useState(false)
    
  const changeStatusCarrito=()=>setopenCarrito(!openCarrito);
  
  
  return  <div className="home-container-mobile">
    <div>
        <AppbarMobile openCarrito={changeStatusCarrito}/>
        <div className="container-categoriasMobile">
        <div>{categorias.map(categoria => categoria!==undefined ? <Categoria categoria={categoria}/> : "")}</div>
        <WhatsappButton show={true} bottom={10} number="5491138141940"/>
    </div>
    </div>
    <CarritoMobile open={openCarrito} close={changeStatusCarrito}/>
  </div>
}

export default HomeMobile
