import React,{useState,useContext,useEffect} from 'react'
import { useHistory,useParams } from "react-router-dom";
import AppContext from '../../../context/AppContext';
import Appbar from '../../../components/appbar'
import {TextField,Button} from "@material-ui/core";
import Centralizador from '../../../components/Centralizador';

const PagoWhatsapp = () => 
{
    const {Envio,ProductosCarrito} = useContext(AppContext);
    const [Opcion, setOpcion] = useState("Efectivo");
    let history = useHistory();
    const {id}=useParams();

    useEffect(() => 
    {
        if(Envio.Direccion===undefined && Envio.Tipo!=="Delivery")
        {
            history.push("/pedido/pago")
        }

        if(Envio.Tipo!=="Delivery")
        {
            history.push("/pedido/pago")
        }
    }, [])

    const Opciones=["Efectivo","Mercado Pago","Tarjeta","Transferencia Bancaria"];

    const Terminar=()=>
    {
       
        if(id==1)
        {
            const API="https://wa.me/5491138141940?text=";

          

            let Texto=`Te paso los detalles para el pedido:`;

            const HorarioFinal=Envio.HorarioEntrega ? Envio.HorarioEntrega : "Lo antes posible";
            Texto+=`\n ${Envio.Tipo} - ${Envio.Direccion} - ${HorarioFinal} - ${Opcion}`;

            window.open(API+Texto.replace(/\n/g,'%0A'), "_blank")
        }
        else
        {
            const API="https://wa.me/5491162321572?text=";

            let Texto=`Te paso los detalles para el pedido:`;

            const HorarioFinal=Envio.HorarioEntrega ? Envio.HorarioEntrega : "Lo antes posible";
            Texto+=`\n ${Envio.Tipo} - ${Envio.Direccion} - ${HorarioFinal} - ${Opcion}`;

            window.open(API+Texto.replace(/\n/g,'%0A'), "_blank")
        }
    }

    const ButtonTerminar=()=>
    {
        return <Button  color="primary" style={{margin:"10px"}} variant="contained" onClick={Terminar}>Terminar</Button>
    }

  

    return (
        <div>
            <Appbar/>
            <Centralizador>
                <div>
            {Opciones.map(opcion =>
                {   
                    return <div style={{margin:"10px"}} ><label> <input type="radio" checked={Opcion === opcion} value={opcion} onChange={e => 
                        {
                            setOpcion(e.target.value);
                        }} name="tipo"/>{opcion}</label></div>
                  
                })}
        </div>
               
                <ButtonTerminar/>
            </Centralizador>
        </div>
    )
}

export default PagoWhatsapp
