import { ImportExportSharp } from "@material-ui/icons";
import {useState,useEffect} from "react";
import DB from "../utils/firebaseAPI";

const useCategorias=()=>
{
    const [Categorias, setCategorias] = useState([]);
    useEffect(() => 
    {
        const CategoriasRef=DB.ref("/Menu/CategoriasDOS");
        CategoriasRef.on("value",snapshot =>
        {
            let categorias =[];
            snapshot.forEach(categoria =>
            {
                let items=[]
                const {Items}=categoria.val();
                if(Items!==undefined)
                {
                   Object.values(Items).map(item =>
                    {
                        const {Estado}=item;
                        Estado && items.push(item);
                    })
                }
                let data=categoria.val();
                items=items.sort((a,b) => (Number(a.Indice) > Number(b.Indice)) ? 1 : ((b.Indice > Number(a.Indice)) ? -1 : 0));
                data.Items=items;
                if(categoria!==undefined)
                {
                    categorias.push({id:categoria.key,data:data})
                }
            })
            categorias= categorias.sort((a,b) => (Number(a.data.Indice) > Number(b.data.Indice)) ? 1 : ((b.data.Indice > Number(a.data.Indice)) ? -1 : 0));
            setCategorias(categorias);
        })
        
        return ()=> CategoriasRef.off("value");
    }, [])
    return Categorias;
}

export default useCategorias;