import React,{useState} from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import styled from "styled-components";
import { CircularProgress, IconButton } from '@material-ui/core';
import { useEffect } from 'react';

const Container=styled.div`
display:flex;
align-items:center;`;

const StyledCantidad=styled.p`
font-size:20px;`;

const ContainerCantidad=styled.div`
font-size:20px;
display:flex;
align-items:center;`;

const Variable = ({variable,Variables}) => 
{
    const {id,data}=variable;
    const [Cantidad, setCantidad] = useState(0);
    const [ExistePrecioPrincipal,setExistePrecioPrincipal]=useState(false);

    useEffect(() => 
    {
        const VariablesArray=Object.entries(Variables).map(variable =>
        {
            return {ID:variable[0],Data:variable[1]}   
        });
        const existePrecioPrincipal=VariablesArray.filter(variable => variable.Data.esPrecioPrincipal).length > 0;
        setExistePrecioPrincipal(existePrecioPrincipal);
    }, [])

    const Remove=()=>
    {
        const handleClick=()=>
        {
            setCantidad(Cantidad-1);
        }
        
        const Activado=Cantidad!==0;
        const color=Activado ? "#3f51b5" : "#9a9a9a";
        
        return <IconButton disabled={!Activado} style={{color,padding:"0px",margin:"5px"}} onClick={handleClick} > 
            <RemoveCircleOutlineIcon style={{fontSize:"30px"}}/>
        </IconButton>    
    }

    const Increment=()=>
    {
        const handleClick=()=>
        {
            setCantidad(Cantidad+1)    
        }

        const Activado=Cantidad<10
        const color=Activado ? "#3f51b5" : "#9a9a9a";
        
        return <IconButton disabled={!Activado} style={{color,padding:"0px",margin:"5px"}} onClick={handleClick} > 
            <AddCircleOutlineIcon style={{fontSize:"30px"}}/>
        </IconButton> 
    }

    var TextoFinal=`${data.Nombre}`

    if(!ExistePrecioPrincipal)
    {
        if(data.Precio!==0)
        TextoFinal=TextoFinal + ` ($${data.Precio})`;
    }

    return <Container>
       <ContainerCantidad>
            <Remove/>
            <p id={id}>{Cantidad}</p>
            <Increment/>
       </ContainerCantidad>
       <div>
           <p>{TextoFinal}</p>
           <p>{}</p>
       </div>
    </Container>
  
    // return (
    //     <Container key={id}>
    //         <ContainerCantidad>
    //             <Remove/>
    //                 <><strong style={{marginRight:"5px"}}>{data.Nombre}</strong> {!ExistePrecioPrincipal && <p>${data.Precio> 0 && data.Precio}</p>} : <p style={{marginLeft:"2.5px"}}  >{` ${Cantidad}`}</p></>
    //             <Increment/>
    //         </ContainerCantidad>
    //         <ContainerDetalles>
    //             <p>{data.Descripcion}</p>
              
    //         </ContainerDetalles>
    //     </Container>
    // )
}

export default Variable
