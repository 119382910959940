import React,{useState,useEffect,useContext} from 'react';
import { useHistory } from "react-router-dom";
import {TextField,Button} from "@material-ui/core";
import Appbar from "../../components/appbar";
import Centralizador from "../../components/Centralizador";
import AppContext from '../../context/AppContext';
import styled from "styled-components";

const ContenedorHorario=styled.div`
margin:15px;`;

const TextoError=styled.h3`
color:red;
margin:5px;`;

const Horario = () => 
{
    const {Envio} = useContext(AppContext);
    const [HorarioEntrega, setHorarioEntrega] = useState(undefined);
    const [LoAntesPosible, setLoAntesPosible] = useState(true);
    const [FueraDeRango, setFueraDeRango] = useState(undefined);
    let history = useHistory();


  

    const handleDateChange = (date) => 
    {
        const Tiempo=(date.target.value);

        const countSeconds = (str) => 
        {
            const [hh = '0', mm = '0', ss = '0'] = (str || '0:0:0').split(':');
            const hour = parseInt(hh, 10) || 0;
            const minute = parseInt(mm, 10) || 0;
            const second = parseInt(ss, 10) || 0;
            return (hour*3600) + (minute*60) + (second);
        };

        const Segundos=countSeconds(Tiempo);
        setHorarioEntrega(date.target.value);

        const MayorA12=Segundos>=43200;
        const MayorA16=Segundos>=57600;
        const MayorA19=Segundos>=68400;
        const MayorA23=Segundos>82800;
        
        if(MayorA12 && !MayorA23)
        { 
            setFueraDeRango(undefined);
        }
        else
        {
            setFueraDeRango("Horario TakeAway: 12 a 23 horas");
        }
        
    };
    

    const Subtitulo=()=>
    {
        const {Tipo}=Envio;
      
        return <h2 style={{margin:"10px 0"}}>Horario de Retiro</h2>;
    }

    const MensajeFueraDeRango=()=>
    {
        return <TextoError>{FueraDeRango}</TextoError>
    }

    const Continuar=()=>
    {
        Envio.LoAntesPosible=LoAntesPosible;
        Envio.HorarioEntrega=HorarioEntrega;
        history.push("/pedido/nombre");
    }




    const TipoHorario=()=>
    {
        setLoAntesPosible(false);
        return "";
    }

    const BotonActivo=()=>
    {
        if(LoAntesPosible)
        {
            return true;
        }
        else
        {
            if(HorarioEntrega===undefined)
            {
                return false;
            }
            if(FueraDeRango)
            {
                return false;
            }
            return true;
        }
    }



    return (
        <>
        <Appbar/>
            <Centralizador>
                <Subtitulo/>
                <TipoHorario/>
              {!LoAntesPosible &&   <ContenedorHorario><TextField type="time" variant="outlined"  value={HorarioEntrega} onChange={handleDateChange}/></ContenedorHorario>}
                <MensajeFueraDeRango/>
                <Button variant="contained" color="primary" disabled={!BotonActivo()} onClick={Continuar} style={{margin:"20px"}}>Continuar</Button>
            </Centralizador>
        </>
    )
}

export default Horario
