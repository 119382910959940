import React,{useState,useContext} from 'react'
import { useHistory } from "react-router-dom";
import AppContext from '../../context/AppContext';
import {TextField,Button} from "@material-ui/core";
import Appbar from '../../components/appbar'
import Centralizador from '../../components/Centralizador';

const Direccion = () => 
{
    const {Envio} = useContext(AppContext);
    const [DirrecionStatus, setDirrecionStatus] = useState("")
    let history = useHistory();

    const Continuar=()=>
    {
        Envio.Direccion=DirrecionStatus;
        
        history.push("/pedido/pago") 
    }

    return (
        <div>
            <Appbar/>
            <Centralizador>
                
                <TextField variant="outlined" label="Direccion" value={DirrecionStatus} onChange={e => setDirrecionStatus(e.target.value)} onKeyDown={e =>
                {
                    if(e.key==="Enter")
                    {
                        Continuar();
                    }
                }}/>
                <p style={{margin:"5px"}}>Ingresar piso y depto  de corresponder</p>
                <div style={{margin:"10px"}}> <Button variant="contained" color="primary" disabled={DirrecionStatus.length<2} onClick={Continuar}>Continuar</Button></div>
            </Centralizador>
        </div>
    )
}

export default Direccion
