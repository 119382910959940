
import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

const AlertMI=props=> 
{
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alert=({type="error",mensaje,close})=>
{
    return  <Snackbar open={mensaje} autoHideDuration={6000} onClose={close}>
    <AlertMI  onClose={close} severity={type}>
     {mensaje}
    </AlertMI>
  </Snackbar>
}

export default Alert;