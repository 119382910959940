import React,{useEffect,useState,useContext} from 'react';
import { useHistory } from "react-router-dom";
import AppContext from '../../context/AppContext';
import Appbar from '../../components/appbar';
import {Button, CircularProgress} from "@material-ui/core";
import styled from "styled-components";
import Centralizador from '../../components/Centralizador';
import axios from 'axios';

const CantidadProducto=styled.p`
display:flex;
justify-content:center;
font-weight:bold;`;

const NombreProducto=styled.p`
margin-left:5px;
font-weight:bold;`;


const Resumen = () => 
{
    const {Envio,ProductosCarrito,Cupon} = useContext(AppContext);
    const [isLoading, setisLoading] = useState(false)
    let history = useHistory();

    useEffect(() => 
    {
        if(Envio.HorarioEntrega===undefined)
        {
            history.push("/pedido/horario")
        }
    }, []);

    const DatosTakeAway=()=>
    {
        const mensaje=`Retiro para las ${Envio.HorarioEntrega} a nombre de ${Envio.Nombre}:`
        return  <p style={{margin:"10px"}}>{mensaje}</p>
    }

    const EnvioDatos=()=>
    {
        var total = ProductosCarrito.reduce((total,producto)=>{
            const {data}=producto;
            const {Precio,Cantidad}=data;
            let precioProducto=Precio*Cantidad;
            return total+precioProducto;
        },0);

        if(Cupon)
        {
            total = total - (total * 10 / 100);
        }

        const ConVuelto=()=>
        {
            if(total!==Envio.AbonaCon)
            {
                const vuelto=Envio.AbonaCon  - total
                return vuelto;
            }
            return 0;
        }
        
        return <>
        {ProductosCarrito.map(producto =>
        {
            return <div key={producto.id} style={{margin:"10px"}}>
                <CantidadProducto >{` -${producto.data.Cantidad} `}<NombreProducto > {producto.data.Nombre}</NombreProducto></CantidadProducto>
                {producto.data.Detalles  &&  <p>({String(producto.data.Detalles).trim()})</p>}
            </div>
        })}
        <p style={{marginTop:"10px"}}>{`Total: $${total}`}</p>
        {Envio.TipoPago==="Efectivo" ? (ConVuelto()===0 ?  <p>{`Pago efectivo justo`}</p> : <p>{`Pago efectivo (vuelto $${ConVuelto()})`}</p>) : <p>{Envio.TipoPago}</p> }
        </>;
    }

    const Enviar=()=>
    {
        const handleEnviar=async ()=>
        {
            let total=0;
            
            if(Cupon)
            {
                setisLoading(true);
                try 
                {
                    const URL_API = process.env.NODE_ENV === "development" ? `http://localhost:8080/review/cupon/${Cupon}` : `https://apibaumlanus.xyz/review/cupon/${Cupon}`;
                    const Peticion = await  axios.delete(URL_API)
                } 
                catch (error) 
                {
                    
                }
                setisLoading(false);
            }


            const API="https://wa.me/5491138141940?text=";
            let Texto="Hola! Quería hacerles un pedido: \n";
            ProductosCarrito.map(producto =>
            {
                const {data}=producto;
                const {Nombre,Cantidad,Detalles,Precio}=data;
                let precioProducto=Precio*Cantidad;
                total+=precioProducto;

                if(Detalles)
                {
                    Texto+=`\n*${Cantidad}* - ${Nombre} ${Detalles}\n`
                }
                else
                {
                    Texto+=`\n*${Cantidad}* - ${Nombre}\n`
                }
            });

            if(Cupon)
            {
                Texto+=`\n*Descuento* - 10%\n`
            }

            Texto+=`\n *Detalles*`;

            const HorarioFinal=Envio.HorarioEntrega ? Envio.HorarioEntrega : "Lo antes posible";
            Texto+=`\nTakeAway a nombre de ${Envio.Nombre} para las ${HorarioFinal} - ${Envio.TipoPago}`;
            
            if(Cupon)
            {
                total = total - (total * 10 / 100);
            }
           

            Texto+=`\nTotal $${total}`;
            
            if(Envio.TipoPago==="Efectivo")
            {
                if(total===Envio.AbonaCon)
                {
                    Texto+=`\nAbono justo\n`;
                }
                else
                {
                    Texto+=`\nAbono con $${Envio.AbonaCon}\n`;
                }
            }
            window.open(API+Texto.replace(/\n/g,'%0A'), "_blank")
        }
        
        if(isLoading) return <CircularProgress size={24} style={{margin:"10px"}}/>

        return <Button style={{margin:"10px"}} variant="contained" color="primary" onClick={handleEnviar}>Enviar</Button>
    }

    return <>
         <Appbar/>
        <Centralizador>
            <DatosTakeAway/>
            <EnvioDatos/>
            <Enviar/>
        </Centralizador>
       </>
}

export default Resumen
