import { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import Index from "./pages/Index";
import IndexPedido from "./pages/pedido/Index";
import AppContext from "./context/AppContext";
import Horario from "./pages/pedido/Horario";
import Nombre from "./pages/pedido/Nombre";
import Direccion from "./pages/pedido/Direccion";
import Pago from "./pages/pedido/Pago";
import Resumen from "./pages/pedido/Resumen";
import IndexPedidoWhatsapp from "./pages/pedido/whatsapp/Index";
import HorarioWhatsapp from "./pages/pedido/whatsapp/Horario";
import NombreWhatsapp from "./pages/pedido/whatsapp/Nombre";
import DireccionWhatsapp from "./pages/pedido/whatsapp/Direccion";
import PagoWhatsapp from "./pages/pedido/whatsapp/Pago";

import './App.css';

const App=()=>
{

  const [ProductosCarrito, setProductosCarrito] = useLocalStorageState('productos', [])
  const [Cupon, setCupon] = useState(undefined)

  const [Envio, setEnvio] = useState
  ({
    Tipo:undefined,
    HorarioEntrega:undefined,
    TipoPago:undefined,
    AbonaCon:undefined,
    Nombre:undefined
  })
  


  return <>
    <AppContext.Provider value={{Envio,setEnvio,ProductosCarrito,setProductosCarrito,Cupon,setCupon}}>
      <BrowserRouter>
        <div className="App">
          <Route path="/" exact component={Index}/>
          <Route path="/pedido" exact component={IndexPedido}/>
          <Route path="/pedido/horario" exact component={Horario}/>
          <Route path="/pedido/nombre" exact component={Nombre}/>
          <Route path="/pedido/direccion" exact component={Direccion}/>
          <Route path="/pedido/pago" exact component={Pago}/>
          <Route path="/pedido/resumen" exact component={Resumen}/>
          <Route path="/pedido/whatsapp/:id" exact component={IndexPedidoWhatsapp}/>
          <Route path="/pedido/whatsapp/:id/Horario" exact component={HorarioWhatsapp}/>
          <Route path="/pedido/whatsapp/:id/nombre" exact component={NombreWhatsapp}/>
          <Route path="/pedido/whatsapp/:id/direccion" exact component={DireccionWhatsapp}/>
          <Route path="/pedido/whatsapp/:id/pago" exact component={PagoWhatsapp}/>
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  </>
}

export default App;
