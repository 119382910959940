import { useState,useContext } from "react";
import { useHistory} from "react-router-dom";
import Appbar from '../../components/appbar';
import Centralizador from "../../components/Centralizador";
import AppContext from '../../context/AppContext';
import {Button } from '@material-ui/core';

const IndexPedido=()=>
{
    const {Envio} = useContext(AppContext);
    const [Opcion,setOpcion]=useState("Delivery");
    const history=useHistory();
  

    const Opciones=()=>
    {
        return <>
     <div style={{margin:"15px"}}>
            <label> 
                <input type="radio" checked={Opcion === "TakeAway"} value="TakeAway" onChange={e => setOpcion("TakeAway")} name="tipo"/>Take Away
                <p>-10% de descuento 🤙</p>
            </label>
        </div>

        <div style={{margin:"15px",marginBottom:"5px"}}>
            <label> 
                <input type="radio" checked={Opcion === "Delivery"} value="Delivery" onChange={e => setOpcion("Delivery")} name="tipo"/>Delivery ⚠️
                <p>- Lunes a sábados de 12 a 16hs</p>
                <p>- Costo de envío $90 </p>
                <p>- Máximo 5 km</p>
            </label>
        </div>
        </>
    }

    const Continuar=()=>
    {
        Envio.Tipo=Opcion;
        history.push("/pedido/horario")
    }

    return <>
        <Appbar/>
        <Centralizador>
            <Opciones/>
            <Button variant="contained" color="primary" onClick={Continuar} style={{margin:"10px"}}>Continuar</Button>
        </Centralizador>
    </>
}
export default IndexPedido