import React,{useState} from 'react';
import ProductoModal from "./productoModal";
import styled from "styled-components";
import { useEffect } from 'react';

const Container=styled.div`
background-color: white;
cursor: pointer;
display: grid;
min-height: 100%;
grid-template-columns: 15% auto;
grid-template-rows: 1fr;
border: 1px solid gray;
border-top: none;
padding:5px;
align-items: self-start;
flex-basis: content;`;

const Img=styled.img`
width: 100%;
height: auto;
object-fit: contain;`;

const Detalles=styled.div`
padding:5px;
display: grid;
grid-template-columns: 80% 20%;
grid-template-rows: 1fr;`;

const Producto = ({producto}) => 
{
    const [openModal, setopenModal] = useState(false);

    const {Nombre,Precio,Imagen,Descripcion,Variables}=producto;
    const [PrecioFinal,setPrecioFinal]=useState(undefined);
    const [ImagenFinal, setImagenFinal] = useState("")
    const [ContieneVariables,setContieneVariables]=useState(undefined);

    useEffect(() => 
    {
        if(Variables===undefined)
        {
          setContieneVariables(false);
          setPrecioFinal(Precio);
        }
        else
        {
            const VariablesArray=Object.entries(Variables).map(variable =>
            {
                return  {ID:variable[0],data:variable[1]}
            })
            var precioPrincipal=0;
            VariablesArray.map(variable =>
            {
             const {esPrecioPrincipal} =variable.data;
             if(esPrecioPrincipal)
             {
                precioPrincipal = variable.data.Precio;
             }
            });
            
            if(precioPrincipal===0)
            {
                setPrecioFinal(Precio);
            }
            else
            {
                setPrecioFinal(precioPrincipal);
            }
            setContieneVariables(true);
        }
    }, [])
   
    return <>
      <Container  onClick={() => setopenModal(!openModal)}>
            <Img alt={`Imagen de ${Nombre}`} src={Imagen}/>
            <Detalles>
                <p className="producto-nombre">{Nombre}</p>
                {PrecioFinal!==0 && <p className="producto-precio">${PrecioFinal}</p>}
                <div className="producto-descripcion">{Descripcion}</div>
            </Detalles>
        </Container>
        <ProductoModal open={openModal} onCloseModal={() => setopenModal(false)} Producto={producto}/>
    </>
}

export default Producto
