import React,{useState,useContext} from 'react';
import { useHistory } from "react-router-dom";
import CarritoContext from "../context/CarritoContext";
import ProductoCarrito from "../components/ProductoCarrito";
import { Drawer,Dialog, DialogActions, DialogContent,Button, TextField } from '@material-ui/core';
import AppContext from '../context/AppContext';
import CuponComponent from './Cupon';


const CarritoMobile = ({open,close}) => 
{
    let history = useHistory();
    const {ProductosCarrito,Cupon }=useContext(AppContext)
    

    const Total=()=>
    {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
          })
        let total=0;

        if(Cupon)
        {
            total = total - (total * 10 / 100);
        }

        return <div>
            {ProductosCarrito.map(producto =>
                {
                    const {data}=producto;
                    const {Precio,Cantidad}=data;
                    let precioProducto=Precio*Cantidad;
                    total+=precioProducto;
                })}
            <p className="Total-carritoDesktop">Subtotal <b>{formatterPeso.format(total)}</b></p>
        </div>
    }

    const ProductosList=()=>
    {
        if(ProductosCarrito.length>0)
        {
           
           return <div>
               {ProductosCarrito.map(producto => <ProductoCarrito  producto={producto}/>)}
           </div>
        }
        return "";
    }

    const enviarPedido=()=>
    {
        history.push("/pedido/horario");
    }

    let FinalizarActivado=ProductosCarrito.length===0;

    const ContainerDrawer=()=>
    {
        return <Drawer open={open} anchor="right" onClose={close} >
        <div className="container-carrito-mobile">
        <p className="Nuevo-pedido-label">Nuevo Pedido</p>
        <ProductosList/>
        <CuponComponent/>
        <Total/>
        <Button disabled={FinalizarActivado} style={{display:"flex",marginLeft:"auto",marginTop:"10px"}} variant="contained" color="primary" onClick={enviarPedido}>Finalizar Pedido</Button>
        </div>
        </Drawer>
    }

    return <div className="Container-carritoMobile">
        <ContainerDrawer/>
    </div>
}

export default CarritoMobile
