import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles=makeStyles(
    {

        Title:
        {
            alignSelf:"center",
            margin:"10px"
        },
    })

const Appbar = () =>
{
    const classes=useStyles();
    const history=useHistory();

    return <div className="appbar-container" style={{cursor:"pointer"}} onClick={() => history.push("/")}>
        <img alt="Imagen de Baum Lanús" src="https://i.imgur.com/O0JFzFr.jpeg" className={"image-appbar"} />
    <h2 className="appbar-title">Menú Digital</h2>
    </div>
}

export default Appbar
