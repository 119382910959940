import {useState,useEffect} from 'react';
import DB from "../utils/firebaseAPI";

const usePopups=()=>
{
    const [Popups, setPopups] = useState([]);
    useEffect(() => 
    {
        DB.ref("/pop-ups").on("value",snapshot =>
        {
            const HoraActual=new Date().getHours();
            const MinutoActual=new Date().getMinutes();

            let popups=[];
            
            snapshot.forEach(popup =>
            {
                const Popup = {id:popup.key, data:popup.val()}

                const HoraExactaInicio = String(Popup.data.HoraInicio).split(":")[0];
                const MinutosInicio = String(Popup.data.HoraInicio).split(":")[1];
                const HoraFin = String(Popup.data.HoraFin).split(":")[0];
                const MinutosFin = String(Popup.data.HoraFin).split(":")[1];
                
                if(HoraActual >= HoraExactaInicio || HoraActual <= HoraFin)
                {
                    if(MinutoActual >= MinutosInicio || MinutoActual <= MinutosFin)
                    {
                        popups.push(Popup);
                    }
                }
            });
            setPopups(popups);
        })
    }, [])
    return Popups;
}

export default usePopups;
