import React,{useState,useEffect,useContext} from 'react'
import { useHistory } from "react-router-dom";
import AppContext from '../../context/AppContext';
import {TextField,Button} from "@material-ui/core";
import Appbar from '../../components/appbar'
import Centralizador from '../../components/Centralizador';

const Nombre = () => 
{
    const {Envio} = useContext(AppContext);
    const [NombreStatus, setNombreStatus] = useState("")
    let history = useHistory();

    useEffect(() => 
    {
        if(Envio.HorarioEntrega===undefined && Envio.LoAntesPosible===false)
        {
            history.push("/pedido");
        }
    }, [])

    const Continuar=()=>
    {
       if(NombreStatus.length>2)
       {
           Envio.Nombre=NombreStatus;
            
            history.push("/pedido/pago")
           
       }
    }

    return (
     <>
      <Appbar/>
        <Centralizador>
            <div>
                <TextField variant="outlined" label="Nombre" size="small" value={NombreStatus} onChange={e => setNombreStatus(e.target.value)} onKeyDown={e => 
                    {
                        if(e.key==="Enter" && NombreStatus.length>2)
                        {
                            Continuar();
                        }
                    }}/>

                <div style={{margin:"10px"}}> 
                    <Button variant="contained" color="primary" disabled={NombreStatus.length<2} onClick={Continuar}>Continuar</Button>
                </div>
            </div>
        </Centralizador>
     </>
    )
}

export default Nombre
