import { useState, useContext } from "react";
import Alert from "./Alert";
import {Button, CircularProgress, TextField } from '@material-ui/core';
import axios from "axios";
import styled from 'styled-components';
import AppContext from "../context/AppContext";

import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const ContainerAdd = styled.div`
margin:10px 0px;
display:flex;
justify-content:space-between;
`;

const ContainerRemove = styled.div`
border-radius:5px;
background-color:rgb(224, 224, 224);
display: flex;
padding: 10px;
justify-content: space-between;

@media (max-width: 600px) {
    
}
`;



const CuponComponent = () => 
{   
    const {Cupon,setCupon}=useContext(AppContext);
    const [Loading, setLoading] = useState(false);


    const [CuponText, setCuponText] = useState("");

    const [showAlert, setshowAlert] = useState("");
    const [typeAlert, settypeAlert] = useState("");

    const checkCupon = async () => 
    {
        setLoading(true);
        const URL_API = process.env.NODE_ENV === "development" ? `http://localhost:8080/review/${CuponText}` : `https://apibaumlanus.xyz/review/${CuponText}`;

        try 
        {
          
            const Peticion = await axios.get(URL_API);  
            setLoading(false);
            setshowAlert(Peticion.data.msg);
            settypeAlert("success");            
            setCupon(CuponText);
        } 
        catch (error) 
        {   
            setLoading(false);
            setshowAlert(error.response.data.msg);
            settypeAlert("error");
        }
        
    }

    return <>
        <ContainerAdd>
            <TextField placeholder='Cupón' variant="outlined"  value={CuponText} onChange={e => setCuponText(e.target.value)}/>
            <Button disabled={Loading} variant="contained" color="primary" onClick={() => checkCupon()}>
            {Loading ? <CircularProgress size={24} /> : "Agregar"}
            </Button>
            <Alert mensaje={showAlert} close={() => setshowAlert("")} type={typeAlert}/>
        </ContainerAdd>

        {Cupon &&   <ContainerRemove>
            <p>{Cupon} -10% Off</p>
            <HighlightOffOutlinedIcon style={{cursor:"pointer"}} onClick={() => setCupon("")}/>
        </ContainerRemove>}
    </>
}

export default CuponComponent