import React,{useState} from 'react';
import styled from "styled-components";
import usePopups from '../hooks/usePopups';
import CancelIcon from '@material-ui/icons/Cancel';

const Modal=styled.div`
background-color: rgba(0, 0, 0, 0.5);
position:fixed;
width:100vw;
height:100vh;
z-index:2;`;

const Container=styled.div`
display:flex;
position:fixed;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
`;

const ImagenStyled=styled.img`
height: 54vh;`;

const Popup=({pop_up})=>
{
    const [OpenModal, setOpenModal] = useState(true);
    const {id,data}=pop_up;
    const {Activo,Imagen}=data;

    const CloseModal=()=>
    {
        const style={color:"white",cursor:"pointer",fontSize:"35px",marginLeft:"-15px",marginTop:"-15px"}

        const Close=()=>
        {
            setOpenModal(false);
        }

        return <CancelIcon style={style} onClick={Close}>X</CancelIcon>
    }
   
    if(OpenModal)
    {
      
        return <Modal>
            <Container>
                <ImagenStyled alt={id} src={Imagen}/>
                <CloseModal/>
            </Container>
        </Modal>
    }
    else
    {
        return "";
    }
}

const Popups = () => 
{
    const pop_ups= usePopups();
    
    return pop_ups.map(pop_up => pop_up.data.Activo && <Popup pop_up={pop_up}/>);   
}

export default Popups
