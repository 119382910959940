import React,{useContext} from 'react';
import CarritoContext from "../context/CarritoContext";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import AppContext from '../context/AppContext';

const ProductoCarrito = ({producto}) => 
{
    const {ProductosCarrito, setProductosCarrito}=useContext(AppContext)
   
    const {id,data}=producto;
   
    const {Nombre,Cantidad,Precio,Imagen,Tipo,Detalles}=data;

    const onClickRemove=()=>
    {
        const newProductos=ProductosCarrito.filter(item => item.id !== id);
        setProductosCarrito(newProductos);
    }

    const PrecioFinal=()=>
    {
        const formatterPeso = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
          })

        return formatterPeso.format(Cantidad*Precio);
    }

    const Botones=()=>
    {
        const changeValue=signo=>
        {
            if(signo==="+")
            {
                const index = ProductosCarrito.findIndex(producto => producto.id === id);
                let copia=[...ProductosCarrito]
                let dataUpdateada={Cantidad:Cantidad+1,Nombre,Precio,Imagen,Tipo,Detalles};
                copia[index]={id,data:dataUpdateada}
                setProductosCarrito(copia);
            }
            if(signo==="-")
            {
                const index = ProductosCarrito.findIndex(producto => producto.id === id);
                let copia=[...ProductosCarrito]
                let dataUpdateada={Cantidad:Cantidad-1,Nombre,Precio,Imagen,Tipo,Detalles};
                copia[index]={id,data:dataUpdateada}
                setProductosCarrito(copia);
            }
        }

        const Remove=()=>
        {
            const Color=Cantidad>1 ? "#3f51b5" : "#9a9a9a";
            const Cursor=Cantidad>1 ? "pointer" : "inherit";
            return <RemoveCircleOutlineIcon style={{cursor:Cursor,margin:"0 5px",color:Color,fontSize:"30px"}} onClick={() => Cantidad>1 && changeValue("-")}/>
        }
    
        const Increment=()=>
        {
            const Color=Cantidad!==10 ? "#3f51b5" : "#9a9a9a";
            const Cursor=Cantidad!==10 ? "pointer" : "inherit";
            return <AddCircleOutlineIcon style={{cursor:Cursor,margin:"0 5px",color:Color,fontSize:"30px"}} onClick={() => Cantidad<10 && changeValue("+")}/>
        }

        return <div style={{display:"flex"}}>
            <Remove/>
            <p style={{alignSelf:"center"}}>{Cantidad}</p>
            <Increment/>
            <p className="Precio-productoCarrito">{PrecioFinal()}</p>
            <div className="Container-removeIcon" onClick={onClickRemove}> <ClearIcon/></div>
        </div>
    }

    const PhotoNameDetalles=()=>
    {
        return <div style={{display:"flex"}}>
         <img src={Imagen} className="Imagen-productoCarrito"/>
         <div style={{margin:"0 16px"}}>

         <p>{Nombre}</p>
         </div>
        </div>
    }
   

    const DetallesUI=()=>
    {
        if(Detalles!==undefined)
        {
            return <p>{Detalles}</p>
        }
        
        return "";
    }

    return (
        <div className="Container-productoCarrito">
            <div className="Container-detallesCarrito">
                <PhotoNameDetalles/>
                <DetallesUI/>
                <Botones/>
            </div>
          
        </div>
    )
}

export default ProductoCarrito
