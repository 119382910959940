import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const WhatsappButton = ({show,number,bottom=0}) =>
{
    const ApiWhatsapp = "https://api.whatsapp.com/send?phone=";

    if(show)
    {
        return (<a href={ApiWhatsapp+number}  style={{bottom:bottom}}  className="float" target="_blank">
        <WhatsAppIcon style={{fontSize:"30px",marginTop:"13px"}}/>
        </a>);
    }
    else
    {
        return "";
    }
}

export default WhatsappButton
