import { useState,useEffect,useContext } from "react";
import { useHistory,useParams  } from "react-router-dom";
import Appbar from '../../../components/appbar';
import Centralizador from "../../../components/Centralizador";
import AppContext from '../../../context/AppContext';
import {Button } from '@material-ui/core';

const IndexPedidoWhatsapp=()=>
{
    const {Envio} = useContext(AppContext);
    const [Opcion,setOpcion]=useState("Delivery");
    let history = useHistory();
    const {id}=useParams();

    const Opciones=()=>
    {
        return <>
        <div style={{margin:"15px",marginBottom:"5px"}}><label> <input type="radio" checked={Opcion === "Delivery"} value="Delivery" onChange={e => setOpcion("Delivery")} name="tipo"/> Delivery </label></div>
        <p style={{margin:"0px"}}>(Costo de envío $90 y hasta 3km máximo)</p>
        <div style={{margin:"15px"}}><label> <input type="radio" checked={Opcion === "TakeAway"} value="TakeAway" onChange={e => setOpcion("TakeAway")} name="tipo"/> Take Away</label></div>
        </>
    }

    const Continuar=()=>
    {
        Envio.Tipo=Opcion;
        history.push(`/pedido/whatsapp/${id}/horario`)
    }

    return <>
        <Appbar/>
        <Centralizador>
            <Opciones/>
            <Button variant="contained" color="primary" onClick={Continuar} style={{margin:"10px"}}>Continuar</Button>
        </Centralizador>
    </>
}
export default IndexPedidoWhatsapp